import {ChainId, FACTORY_ADDRESS_MAP, Pair, WETH9} from "@pancakeswap/sdk";
import { chains } from "@icecreamswap/constants";
import {coreTokens, cronosTokens, ICE, USD, STABLE_COIN} from "@pancakeswap/tokens";

export const nativeStableLpMap = {...chains.reduce((acc, chain) => {
  if (!WETH9[chain.id] || !USD[chain.id] || !FACTORY_ADDRESS_MAP[chain.id]) return acc
  return {...acc, [chain.id]: {
      address: Pair.getAddress(WETH9[chain.id], STABLE_COIN[chain.id]),
      wNative: WETH9[chain.id].symbol,
      stable: STABLE_COIN[chain.id].symbol,
    }}
}, {})/*,
  [ChainId.CORE]: {
    address: Pair.getAddress(coreTokens.wcore_old, USD[ChainId.CORE]),
    wNative: coreTokens.wcore_old.symbol,
    stable: USD[ChainId.CORE].symbol,
  } */
    ,
    [ChainId.CRONOS]: {
      address: Pair.getAddress(cronosTokens.wcro, cronosTokens.usdt),
      wNative: cronosTokens.wcro.symbol,
      stable: cronosTokens.usdt.symbol,
    }
}