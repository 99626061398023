import { SignJWT } from 'jose';

let JWT_SECRET: string | undefined = process.env.NEXT_PUBLIC_JWT_SECRET
if (process.env.VERCEL_ENV === 'production') {
  JWT_SECRET = process.env.JWT_SECRET
}

// Function to generate a short-lived token
export const generateToken = async (): Promise<string> => {
  const payload = {
    app: 'Obsidian',
    timestamp: Date.now(), // Include a unique timestamp
  };

  // Sign and generate the token
  const jwt = await new SignJWT(payload)
    .setProtectedHeader({ alg: 'HS256' }) // Define signing algorithm
    .setExpirationTime('5m') // Expires in 5 minutes
    .sign(new TextEncoder().encode(JWT_SECRET)); // Encode the secret

  // Return the signed JWT token
  return jwt;
};