import { useState, useEffect, useCallback } from 'react';
import { useAccount } from 'wagmi';
import { REWARDS_API } from 'config/constants/endpoints';
import { useActiveChainId } from 'hooks/useActiveChainId';
import { generateToken } from 'strict/utils/jwtToken';

interface PrizeHistoryItem {
  prize: string;
  claimedAt: string;
}

interface TradeStatusResponse {
  dailyVolume: number;
  hasClaimed: boolean;
  diamonds: number;
  lastClaimTime: string | null;
  nextClaimTime: string;
  nextResetTime: string;
  prizeHistory: PrizeHistoryItem[];
}

interface TradeStatus {
  isLoading: boolean;
  diamonds: number;
  canClaim: boolean;
  dailyTradeVolume: number;
  hasClaimed: boolean;
  lastClaimTime: string | null;
  nextClaimTime: string;
  nextResetTime: string;
  prizeHistory: PrizeHistoryItem[];
  error: Error | null;
}

interface TradeStatusWithSettersAndRefetch extends TradeStatus {
  setDiamonds: (diamonds: number) => void;
  refetch: () => Promise<void>;
}

export const useTradeStatus = (minTradeValue: number): TradeStatusWithSettersAndRefetch => {
  const [status, setStatus] = useState<TradeStatus>({
    isLoading: true,
    diamonds: 0,
    canClaim: false,
    dailyTradeVolume: 0,
    hasClaimed: false,
    lastClaimTime: null,
    nextClaimTime: '',
    nextResetTime: '',
    prizeHistory: [],
    error: null,
  });

  const { address: userAddress, isConnected } = useAccount();
  const { chainId } = useActiveChainId();

  // Function to fetch user trade status
  const fetchUserStatus = useCallback(async () => {
    if (!isConnected) {
      setStatus(prev => ({ ...prev, isLoading: false }));
      return;
    }

    try {
      const token = await generateToken();
      const response = await fetch(
        `${REWARDS_API}/${chainId}/v1/trade-status?chainId=${chainId}&address=${userAddress}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );

      if (!response.ok) {
        throw new Error('Failed to fetch trade status');
      }

      const data: TradeStatusResponse = await response.json();
      setStatus({
        isLoading: false,
        diamonds: data.diamonds,
        canClaim: data.dailyVolume >= minTradeValue && !data.hasClaimed,
        dailyTradeVolume: data.dailyVolume,
        hasClaimed: data.hasClaimed,
        lastClaimTime: data.lastClaimTime,
        nextClaimTime: data.nextClaimTime,
        nextResetTime: data.nextResetTime,
        prizeHistory: data.prizeHistory,
        error: null,
      });
    } catch (error) {
      setStatus(prev => ({
        ...prev,
        isLoading: false,
        error: error as Error,
      }));
    }
  }, [userAddress, chainId, isConnected, minTradeValue]);

  // Function to set diamonds manually
  const setDiamonds = (newDiamonds: number) => {
    setStatus(prev => ({
      ...prev,
      diamonds: newDiamonds,
    }));
  };

  // Exposed function to trigger a re-fetch
  const refetch = useCallback(async () => {
    setStatus(prev => ({ ...prev, isLoading: true }));
    await fetchUserStatus();
  }, [fetchUserStatus]);

  // Initial fetch on mount
  useEffect(() => {
    fetchUserStatus();
  }, [fetchUserStatus]);

  return {
    ...status,
    setDiamonds,
    refetch, // Expose the refetch function
  };
};
