import { getAddress } from "viem";
import { SerializedFarmConfig } from '@pancakeswap/farms'
import { cronosTokens } from '@pancakeswap/tokens'

const priceHelperLps: SerializedFarmConfig[] = [
    {
        pid: null,
        lpSymbol: '',
        lpAddress: '0x99C51537dc2369F5Ed83C19A3dc052d3C75b089B',
        token: cronosTokens.wcro,
        quoteToken: cronosTokens.usdt,
    },
].map((p) => ({ 
    ...p,
    token: p.token.serialize,
    quoteToken: p.quoteToken.serialize,
    lpAddress: getAddress(p.lpAddress),
}))

export default priceHelperLps

