import { trpcClient } from '@icecreamswap/backend'
import { TokenList } from '@pancakeswap/token-lists'
import { QUOTING_API } from 'config/constants/endpoints'
import { generateToken } from './jwtToken'

export const getDefaultTokenList = async (): Promise<TokenList> => {
  // @ts-ignore
  return trpcClient.token.defaultList.query()
}


export const getPuushGraduatesList = async (): Promise<TokenList | null> => {
  try {
    const token = await generateToken(); 
    const response = await fetch(`${QUOTING_API}/25/v1/graduates`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    
    if (!response.ok) {
      console.error('Error fetching Puush Graduates token list');
      return null;
    }
    
    const tokenList = await response.json();
    
    return tokenList;
  } catch (error) {
    console.error('Error fetching Puush Graduates token list:', error);
    return null;
  }
}