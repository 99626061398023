import { ChainId } from '@pancakeswap/sdk'
import { ComputedFarmConfigV3 } from '../../src/types'
import { FarmV3SupportedChainId } from '../../src'
import { farmsV3 as farm25 } from '../25'
// import { farmsV3 as farm1116 } from '../1116'

export const farmsV3ConfigChainMap: Record<ChainId, ComputedFarmConfigV3[]> = {
//   [ChainId.CORE]: farm1116,
 //  [ChainId.CRONOS]: farm25,
}
