import { DefaultSeoProps } from 'next-seo'

export const SEO: DefaultSeoProps = {
  titleTemplate: '%s | Smart Dex Aggregator',
  defaultTitle: 'Obsidian',
  description:
    'DEX, DEX Aggregator, Launchpad and Bridge on Cronos, zkCRo, and more',
  twitter: {
    cardType: 'summary_large_image',
    handle: '@obsidianSwap',
    site: '@obsidianSwap',
  },
  openGraph: {
    title: 'Obsidian - A next evolution DeFi exchange on Cronos Chain',
    description:'DEX, DEX Aggregator, Launchpad and Bridge on Cronos, zkCRo, and more.',
    images: [{ url: 'https://obsidian.finance/images/hero.png' }],
  },
}
