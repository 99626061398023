import IceChain from '../ice-chain'

export const cronoszkTestnet: IceChain = {
  id: 282,
  name: 'Cronos zkEVM Testnet',
  features: ['swap'],
  network: 'cronoszkTestnet',
  rpcUrls: {
    public: { http: ['https://testnet.zkevm.cronos.org',] },
    default: { http: ['https://testnet.zkevm.cronos.org',] },
  },
  blockExplorers: {
    default: { name: 'Cronos zkEVM Explorer', url: 'https://explorer.zkevm.cronos.org/testnet' },
  },
  nativeCurrency: {
    name: 'zkCRO',
    symbol: 'zkCRO',
    decimals: 18,
  },
  contracts: {
    multicall3: {
      address: '0xc4136564e11410Bd3C8f46D164A2BDa435c4C480',
      blockCreated: 222379,
    },
  },
  blockInterval: 1,
  wrappedNative: {
    address: '0xf9bb37013de8cd3f89b3623af9ee1b1b32d872c9',
    decimals: 18,
    symbol: 'wzkCRO',
    name: 'Wrapped zkCRO',
  },
  // iceAddress: '0x664E4b17EA045fE92868821F3eE0a76a5dB38166',
  stableToken: {
    address: '0x66c8221E5938A296D7542aB3D7cB856e789C06B2',
    decimals: 18,
    symbol: 'vUSD',
    name: 'Veno USD',
  },
  swap: {
    factoryAddress: '0xF0b5c912DD826d46C691056dA0358fb459CAee96',
    // factoryAddress: '0x393ACA54745c50015d17c4e16C20fC2c682c3FA0',
    initCodeHash: '0x0100053bebd525bbc9cc7030eb1f3692de927beb0f45b16cb76401062f37c377',
    routerAddress: '0x695ce3344FBe7B38000B0f38a7dd1F1Fd0D996FA',
  },
  //farmV2Address: '0x8a44aC7D38B9925D2437803520ED38ae5C3120e5',
  /*
  v3:{
    factoryAdress:'0x94fFa46F0831311152F0dbe5D4C74d54268533d5'
    initCodeHash: '0xf16b1c067611794c79bef6ff7630d4a4c43b3be76c08085ce934747817a6b322',
  },
  */
  smartRouterAddress: '0x0191b90fE91C689E46b7E820f5503cc25552Fb80',
}