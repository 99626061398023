import { erc20ABI } from "wagmi";
import { dexScreenerUSD } from "./dexScreenerPrice";
import { publicClient } from './wagmi';
import { pancakePairV2ABI } from "@pancakeswap/sdk";
import { Address } from "viem";

interface LPDetails {
  reserve0: bigint;
  reserve1: bigint;
  totalSupply: bigint;
  token0: string;
  token1: string;
  decimals0: number;
  decimals1: number;
  lpDecimals: number;
}

export const fetchLPDetails = async (
  lpTokenAddress: Address,
  chainId: number
): Promise<LPDetails> => {
  const client = publicClient({ chainId });

  try {
    // Fetch reserves
    const [reserve0, reserve1] = await client.readContract({
      abi: pancakePairV2ABI,
      address: lpTokenAddress,
      functionName: 'getReserves',
    });

    // Fetch token addresses
    const token0: string = await client.readContract({
      abi: pancakePairV2ABI,
      address: lpTokenAddress,
      functionName: 'token0',
    });

    const token1: string = await client.readContract({
      abi: pancakePairV2ABI,
      address: lpTokenAddress,
      functionName: 'token1',
    });

    // Fetch total supply
    const totalSupply: bigint = await client.readContract({
      abi: pancakePairV2ABI,
      address: lpTokenAddress,
      functionName: 'totalSupply',
    });

    // Fetch decimals for both tokens and LP token
    const decimals0: number = await client.readContract({
      abi: erc20ABI,
      address: token0 as Address,
      functionName: 'decimals',
    });

    const decimals1: number = await client.readContract({
      abi: erc20ABI,
      address: token1 as Address,
      functionName: 'decimals',
    });

    const lpDecimals: number = await client.readContract({
      abi: erc20ABI,
      address: lpTokenAddress,
      functionName: 'decimals',
    });

    return {
      reserve0,
      reserve1,
      totalSupply,
      token0,
      token1,
      decimals0,
      decimals1,
      lpDecimals,
    };
  } catch (error) {
    console.error('Error fetching LP details:', error);
    throw new Error('Failed to fetch LP details');
  }
};

// New function to fetch single token price
const fetchTokenPrice = async (
  chainId: number,
  tokenAddress: string
): Promise<number> => {
  try {
    const priceMap = await dexScreenerUSD(chainId, [tokenAddress]);
    const price = parseFloat(priceMap.get(tokenAddress) || '0');
    if (price === 0) {
      throw new Error(`Failed to fetch price for token ${tokenAddress}`);
    }
    return price;
  } catch (error) {
    console.error(`Error fetching price for token ${tokenAddress}:`, error);
    throw error;
  }
};

export const getLPTokenPrice = async (
  chainId: number,
  lpTokenAddress: Address,
): Promise<number> => {
  try {
    // Fetch LP details
    const {
      reserve0,
      reserve1,
      totalSupply,
      token0,
      token1,
      decimals0,
      decimals1,
      lpDecimals
    } = await fetchLPDetails(lpTokenAddress, chainId);

    // Fetch token prices individually
    //console.log('Fetching price for token0:', token0);
    const token0Price = await fetchTokenPrice(chainId, token0);
    
    //console.log('Fetching price for token1:', token1);
    const token1Price = await fetchTokenPrice(chainId, token1);

    // Convert reserves to decimal numbers considering token decimals
    const adjustedReserve0 = Number(reserve0) / (10 ** decimals0);
    const adjustedReserve1 = Number(reserve1) / (10 ** decimals1);
    const adjustedTotalSupply = Number(totalSupply) / (10 ** lpDecimals);

    // Calculate total value in USD
    const totalValueInUSD = (adjustedReserve0 * token0Price) +
                           (adjustedReserve1 * token1Price);

    // Calculate LP token price
    const lpTokenPrice = totalValueInUSD / adjustedTotalSupply;

    console.log({
      token0Price,
      token1Price,
      adjustedReserve0,
      adjustedReserve1,
      adjustedTotalSupply,
      totalValueInUSD,
      lpTokenPrice
    });
    return lpTokenPrice;
  } catch (error) {
    console.error('Error calculating LP token price:', error);
    throw error;
  }
};