import { useEffect, useState } from 'react';
import { ethers } from 'ethers';
import { Address, namehash } from 'viem';
import truncateHash from '@pancakeswap/utils/truncateHash';
import { truncateEns } from 'utils/truncate';
import { cronos } from '@icecreamswap/constants/src/chains/cronos';

const registryContractAbi = [
    {
      constant: true,
      inputs: [
        {
          internalType: "bytes32",
          name: "node",
          type: "bytes32",
        },
      ],
      name: "resolver",
      outputs: [
        {
          internalType: "address",
          name: "",
          type: "address",
        },
      ],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: true,
      inputs: [
        {
          internalType: "bytes32",
          name: "node",
          type: "bytes32",
        },
      ],
      name: "owner",
      outputs: [
        {
          internalType: "address",
          name: "",
          type: "address",
        },
      ],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
  ];
  
  // // Resolver smart contract
  
  const resolverContractAbi = [
    {
      constant: true,
      inputs: [
        {
          internalType: "bytes32",
          name: "",
          type: "bytes32",
        },
      ],
      name: "name",
      outputs: [
        {
          internalType: "string",
          name: "",
          type: "string",
        },
      ],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
  ];

const useCroId = (address: Address, chainId: number) => {
    const [shortEnsName, setEnsName] = useState<string | null>(null);
    const shortAddress = address ? truncateHash(address) : '';

    useEffect(() => {
        const fetchCroId = async () => {
            // Ensure RPC URLs are correctly defined in your chain object
            const rpcUrl = cronos.rpcUrls.default.http[0];
            if (!rpcUrl) return; // Early return if no RPC URL is found

            const provider = new ethers.providers.JsonRpcProvider(rpcUrl);
            const ensAddress = '0x7F4C61116729d5b27E5f180062Fdfbf32E9283E5'; // Example ENS address

            try {
                const derived_domain = address.toLowerCase().substring(2) + ".addr.reverse";
                const derived_node = namehash(derived_domain);

                const ethersContract = new ethers.Contract(
                    ensAddress,
                    registryContractAbi,
                    provider
                );

                const resolverContractAddress = await ethersContract['resolver'](derived_node);

                const ethersResolverContract = new ethers.Contract(
                    resolverContractAddress,
                    resolverContractAbi,
                    provider
                );

                const ensName = await ethersResolverContract['name'](derived_node);
                const shortName = ensName ? truncateEns(ensName) : null;
                setEnsName(shortName);
            } catch (error) {
                console.error('Error fetching Cronos ID:', error);
            }
        }

        // Conditional check to ensure the chain ID matches 25
        if (address && chainId === 25) {
            fetchCroId();
        } else {
            setEnsName(null); // Optionally reset the ENS name if the condition is not met
        }

    }, [address, chainId]);

    return { shortEnsName, shortAddress };
};

export default useCroId;